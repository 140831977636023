// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ambulantes-operieren-jsx": () => import("./../../../src/pages/ambulantes-operieren.jsx" /* webpackChunkName: "component---src-pages-ambulantes-operieren-jsx" */),
  "component---src-pages-anti-aging-jsx": () => import("./../../../src/pages/anti-aging.jsx" /* webpackChunkName: "component---src-pages-anti-aging-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-konservative-therapien-jsx": () => import("./../../../src/pages/konservative-therapien.jsx" /* webpackChunkName: "component---src-pages-konservative-therapien-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-krankheitsbilder-jsx": () => import("./../../../src/pages/krankheitsbilder.jsx" /* webpackChunkName: "component---src-pages-krankheitsbilder-jsx" */),
  "component---src-pages-operationen-jsx": () => import("./../../../src/pages/operationen.jsx" /* webpackChunkName: "component---src-pages-operationen-jsx" */),
  "component---src-pages-schwerpunkte-jsx": () => import("./../../../src/pages/schwerpunkte.jsx" /* webpackChunkName: "component---src-pages-schwerpunkte-jsx" */),
  "component---src-pages-stationaeres-operieren-jsx": () => import("./../../../src/pages/stationaeres-operieren.jsx" /* webpackChunkName: "component---src-pages-stationaeres-operieren-jsx" */),
  "component---src-pages-stellenangebote-jsx": () => import("./../../../src/pages/stellenangebote.jsx" /* webpackChunkName: "component---src-pages-stellenangebote-jsx" */),
  "component---src-pages-suche-jsx": () => import("./../../../src/pages/suche.jsx" /* webpackChunkName: "component---src-pages-suche-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-templates-illness-jsx": () => import("./../../../src/templates/illness.jsx" /* webpackChunkName: "component---src-templates-illness-jsx" */),
  "component---src-templates-operation-jsx": () => import("./../../../src/templates/operation.jsx" /* webpackChunkName: "component---src-templates-operation-jsx" */),
  "component---src-templates-therapy-jsx": () => import("./../../../src/templates/therapy.jsx" /* webpackChunkName: "component---src-templates-therapy-jsx" */)
}

